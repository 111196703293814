// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-talks-js": () => import("./../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-words-js": () => import("./../src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */),
  "component---src-templates-menu-all-events-template-js": () => import("./../src/templates/menu/AllEventsTemplate.js" /* webpackChunkName: "component---src-templates-menu-all-events-template-js" */),
  "component---src-templates-menu-event-template-js": () => import("./../src/templates/menu/EventTemplate.js" /* webpackChunkName: "component---src-templates-menu-event-template-js" */),
  "component---src-templates-menu-item-template-js": () => import("./../src/templates/menu/ItemTemplate.js" /* webpackChunkName: "component---src-templates-menu-item-template-js" */),
  "component---src-templates-menu-menu-template-js": () => import("./../src/templates/menu/MenuTemplate.js" /* webpackChunkName: "component---src-templates-menu-menu-template-js" */),
  "component---src-templates-menu-what-is-this-js": () => import("./../src/templates/menu/WhatIsThis.js" /* webpackChunkName: "component---src-templates-menu-what-is-this-js" */),
  "component---src-templates-post-index-js": () => import("./../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */)
}

